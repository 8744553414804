import {
    constructRoutes,
    constructApplications,
    constructLayoutEngine,
  } from "single-spa-layout";
import { registerApplication, start } from "single-spa";
import { importApp } from './helpers/import-app'

let importedApps = [];
let shareScope = [];

importApp('@phoenix/app/ApiUser', shareScope, importedApps).then(apiUser => {
  return apiUser.default.getRoles().then((response) => {
    if (response === undefined) {
      return
    }
    const routes = constructRoutes(document.querySelector("#single-spa-layout"), {
      loaders: {
        app: "<h1>Checking authentication...</h1>",
      },
      errors: {
        app: "<h1>Failed Loading app</h1>",
      },
    });

    const applications = constructApplications({
      routes,
      loadApp: ({ name }) => {
        return importApp(name, shareScope, importedApps)
      },
    });

    const layoutEngine = constructLayoutEngine({
      routes,
      applications,
      active: false,
    });

    applications.forEach(registerApplication);
    
    start();
  })
})
